import React from 'react'
import PropTypes from 'prop-types'

import ReactOtpInput from 'react-otp-input'

import useStyles from './style'

const OtpInput = ({ errorMessage, hasErrored, wrapperStyle, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={wrapperStyle}>
      <ReactOtpInput
        separator={<span style={{ marginRight: '1rem' }} />}
        inputStyle={classes.apCustomOTPInput}
        errorStyle={classes.apCustomOTPError}
        focusStyle={`${hasErrored ? '' : classes.apCustomOTPFocus}`}
        hasErrored={hasErrored}
        {...rest}
      />
      {hasErrored && errorMessage && <div className={classes.apCustomOTPErrorMessage}>{errorMessage}</div>}
    </div>
  )
}

OtpInput.propTypes = {
  errorMessage: PropTypes.string,
  wrapperStyle: PropTypes.string,
  hasErrored: PropTypes.bool,
}

export default OtpInput
