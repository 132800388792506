import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import mySaga from 'sagas'
import reducer from './reducers'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// mount it on the Store
// export default createStore(reducer, applyMiddleware(sagaMiddleware))
export default createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

// then run the saga
sagaMiddleware.run(mySaga)
