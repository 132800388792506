import React, { useState, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useSnackbar } from 'notistack'

import { ThemeContext } from 'context/themeContext'

import { OuterLogo } from 'components/Common'
import TwoFAForm from 'components/TwoFAForm'

import Button from 'components/Button'
import AuthenticationWrapper from 'components/AuthenticationWrapper'
import TextField from 'components/TextField'

import { login, clearLoginMessage, getProfileInfo, clearResendOTP } from './actions'
import useStyles from './style'

const LoginPage = (props) => {
  const { isLoading, loginError, profile, OTPInfo, postResendOTPSuccess, postResendOTPError } = props.data
  const { history, location, postLogin, onClearLoginMessage, fetchProfileInfo, onClearResendOTP } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile } = useContext(ThemeContext)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState('')

  useEffect(() => {
    if (loginError) {
      enqueueSnackbar(loginError, {
        variant: 'error',
        onClose: () => onClearLoginMessage(),
      })
    }
  }, [enqueueSnackbar, loginError, onClearLoginMessage])

  useEffect(() => {
    if (postResendOTPSuccess) {
      enqueueSnackbar(postResendOTPSuccess, {
        variant: 'success',
        onClose: () => onClearResendOTP(),
      })
    }
    if (postResendOTPError) {
      enqueueSnackbar(postResendOTPError, {
        variant: 'error',
        onClose: () => onClearResendOTP(),
      })
    }
  }, [enqueueSnackbar, postResendOTPSuccess, postResendOTPError, onClearResendOTP])

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search)
    if (parsedQuery.token) {
      localStorage.setItem('token', parsedQuery.token)
      history.replace('/login')
      fetchProfileInfo()
    }
  }, [fetchProfileInfo, history, location.search])

  if (profile) {
    history.push('/')
  }

  const getFormContent = () => {
    if (OTPInfo) {
      return (
        <TwoFAForm
          handleReset={() => {
            setUsername('')
            setPassword('')
          }}
        />
      )
    }
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          postLogin({ username, password })
        }}
      >
        {isMobile && <OuterLogo />}
        <Typography variant='h6'>Sign in to your account</Typography>
        <TextField
          id='username'
          label='Email or Username'
          value={username}
          handleChange={(val) => setUsername(val)}
          autoFocus
          required
          fullWidth
          customClasses={classes.loginTextField}
        />
        <TextField
          id='password'
          label='Password'
          value={password}
          handleChange={(val) => setPassword(val)}
          type={showPassword ? 'text' : 'password'}
          required
          fullWidth
          customClasses={classes.loginTextField}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword((prevState) => !prevState)}
                edge='end'
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant='contained'
          size='large'
          color='primary'
          type='submit'
          fullWidth
          disabled={isLoading}
          buttonRootClass={classes.loginButtonRoot}
          actionLoading={isLoading}
          buttonText='SIGN IN NOW'
        />
      </form>
    )
  }

  return (
    <AuthenticationWrapper>
      <div className={classes.profileContent}>{getFormContent()}</div>
    </AuthenticationWrapper>
  )
}

LoginPage.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  postLogin: PropTypes.func,
  onClearLoginMessage: PropTypes.func,
  fetchProfileInfo: PropTypes.func,
  onClearResendOTP: PropTypes.func,
}

const mapStateToProps = (state) => ({ data: state.LoginReducer })

const mapDispatchToProps = (dispatch) => ({
  postLogin: (credential) => dispatch(login(credential)),
  onClearLoginMessage: () => dispatch(clearLoginMessage()),
  fetchProfileInfo: () => dispatch(getProfileInfo()),
  onClearResendOTP: () => dispatch(clearResendOTP()),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRouter, withConnect)(LoginPage)
