const clone = (obj) => ({ ...obj })

const renameKey = (object, oldKey, newKey) => {
  const clonedObj = clone(object)

  const keys = Object.keys(clonedObj)
  const newObj = keys.reduce((acc, val) => {
    if (val === oldKey) {
      acc[newKey] = clonedObj[oldKey]
    } else {
      acc[val] = clonedObj[val]
    }
    return acc
  }, {})

  return newObj
}

export default renameKey
