import validateEmail from 'utils/validateEmail'

export const studentResult = (array) => {
  const formattedArray = array.reduce((obj, d, index) => {
    // If the length doesn't already exist as a key in the object, create it
    const hasBarProperty = Object.prototype.hasOwnProperty.call(obj, d?.className)
    let remark = ''
    if (!hasBarProperty) {
      // eslint-disable-next-line no-param-reassign
      obj[d?.className] = []
    }

    if (obj[d?.className].length) {
      const found = obj[d?.className].find((a) => a?.userName === d?.studentEmail?.toLowerCase())

      if (found) remark = 'Duplicate Email'
    }

    const isEmailValid = validateEmail(d?.studentEmail)
    if (!isEmailValid) remark = 'Email not valid'

    const studentObject = {
      id: index,
      userName: d?.studentEmail,
      studentName: d?.studentName,
      studentPassword: d?.password || '',
      className: [d?.className],
      remark,
    }

    obj[d?.className].push(studentObject)

    return obj
  }, {})

  return formattedArray
}

export const teacherResult = (array) => {
  const formattedArray = array.reduce((obj, d, index) => {
    // If the length doesn't already exist as a key in the object, create it
    const hasBarProperty = Object.prototype.hasOwnProperty.call(obj, d?.className)
    let remark = ''
    if (!hasBarProperty) {
      // eslint-disable-next-line no-param-reassign
      obj[d?.className] = []
    }

    if (obj[d?.className].length) {
      const found = obj[d?.className].find((a) => a?.userName === d?.teacherEmail?.toLowerCase())

      if (found) return obj
    }

    const isEmailValid = validateEmail(d?.teacherEmail)
    if (!isEmailValid) remark = 'Email not valid'

    const teacherObject = {
      id: index,
      userName: d?.teacherEmail?.toLowerCase(),
      teacherName: d?.teacherName,
      teacherPassword: d?.password || '',
      className: [d?.className],
      remark,
    }

    obj[d?.className].push(teacherObject)

    return obj
  }, {})

  return formattedArray
}
