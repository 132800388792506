/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import XLSX from 'xlsx'
const { studentResult, teacherResult } = require('./formatter')

let finalJson

// create JSON object from 2 dimensional Array
const arrToObject = (arr, subjectName) => {
  // assuming header
  const keys = ['className', 'studentName', 'studentEmail', 'teacherName', 'teacherEmail']
  const formatted = []
  const data = arr
  const cols = keys
  const l = cols.length
  for (let i = 0; i < data.length; i++) {
    const d = data[i]
    const o = {}
    for (let j = 0; j < l; j++) {
      if (cols[j] === 'className') d[j] = `${d[j]} (${subjectName})`
      o[cols[j]] = d[j]
    }
    formatted.push(o)
  }
  return formatted
}

const parseExcel = async (sheetData) => {
  const classNames = []
  let dataArray = []
  let classGroupedStudents = {}
  let classGroupedTeachers = {}

  return new Promise((resolve, reject) => {
    if (sheetData.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      reject(new Error(`File format didn't match`))

    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
      const sheets = wb.SheetNames
      if (sheets.length < 2) reject(new Error(`Excel sheet format doesn't match`))

      sheets.shift()

      for (const sheet of sheets) {
        const regexPattern = new RegExp(/(?<=\s)(.*)(?=\s)/)
        const regexMatch = sheet.match(regexPattern)
        const subjectName = regexMatch && regexMatch[0]

        const ws = wb.Sheets[sheet]
        const rows = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false })
        rows.shift()

        const formattedArrayToObject = arrToObject(rows, subjectName)
        dataArray = dataArray.concat(formattedArrayToObject)
        classGroupedStudents = studentResult(dataArray)
        classGroupedTeachers = teacherResult(dataArray)
      }

      for (const key in classGroupedStudents) {
        if ({}.hasOwnProperty.call(classGroupedStudents, key)) {
          classNames.push({ name: key, displayName: key })
        }
      }

      finalJson = {
        classList: classNames,
        students: classGroupedStudents,
        teachers: classGroupedTeachers,
      }

      const data = finalJson
      resolve(data)
    }

    if (rABS) reader.readAsBinaryString(sheetData)
    else reader.readAsArrayBuffer(sheetData)
  })
}

export default parseExcel
