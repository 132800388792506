export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const GET_PROFILE_INFO = 'LOGIN/GET_PROFILE_INFO'
export const GET_PROFILE_INFO_SUCCESS = 'LOGIN/GET_PROFILE_INFO_SUCCESS'
export const GET_PROFILE_INFO_ERROR = 'LOGIN/GET_PROFILE_INFO_ERROR'

export const CLEAR_LOGIN_MESSAGE = 'CLEAR_LOGIN_MESSAGE'

export const SET_AUTHENTICATION_RESPONSE = 'AP/LoginPage/SET_AUTHENTICATION_RESPONSE'

export const CHANGE_OTP_INFO = 'AP/LoginPage/CHANGE_OTP_INFO'

export const POST_OTP_CODE = 'AP/LoginPage/POST_OTP_CODE'
export const POST_OTP_CODE_SUCCESS = 'AP/LoginPage/POST_OTP_CODE_SUCCESS'
export const POST_OTP_CODE_ERROR = 'AP/LoginPage/POST_OTP_CODE_ERROR'

export const POST_RESEND_OTP = 'AP/LoginPage/POST_RESEND_OTP'
export const POST_RESEND_OTP_SUCCESS = 'AP/LoginPage/POST_RESEND_OTP_SUCCESS'
export const POST_RESEND_OTP_ERROR = 'AP/LoginPage/POST_RESEND_OTP_ERROR'

export const CLEAR_RESEND_OTP = 'AP/LoginPage/CLEAR_RESEND_OTP'
export const CLEAR_POST_OTP = 'AP/LoginPage/CLEAR_POST_OTP'
