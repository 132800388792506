import React from 'react'
import PropTypes from 'prop-types'

import InputLabel from '@material-ui/core/InputLabel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import useStyles from './style'

const CustomSelectField = ({ id, label, items, initialValue, isSearchable, onSelectValue, ...rest }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(initialValue || null)

  const handleChange = (event) => {
    setValue(event.target.value)
    onSelectValue(event.target.value)
  }

  return (
    <div>
      {isSearchable ? (
        <Autocomplete
          id={id}
          options={items}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
            onSelectValue(id, newValue)
          }}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={label} />}
          {...rest}
        />
      ) : (
        <FormControl className={classes.formControl}>
          <InputLabel id={id}>{label}</InputLabel>
          <Select labelId={id} id={id} value={value} onChange={handleChange}>
            {(items || []).map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

CustomSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  initialValue: PropTypes.object,
  isSearchable: PropTypes.bool,
  onSelectValue: PropTypes.func.isRequired,
}

export default CustomSelectField
