/* eslint-disable no-param-reassign */
import produce from 'immer'
import {
  GET_SCHOOL_LIST,
  GET_SCHOOL_LIST_SUCCESS,
  GET_SCHOOL_LIST_ERROR,
  GET_SUBJECT_LIST,
  GET_SUBJECT_LIST_SUCCESS,
  GET_SUBJECT_LIST_ERROR,
  POST_BULK_INSERT,
  POST_BULK_INSERT_RESPONSE,
  CLEAR_MESSAGE,
} from './constants'

export const initialState = {
  schoolLoading: false,
  schoolList: [],
  schoolListError: '',
  schoolId: false,
  subjectLoading: false,
  subjectList: [],
  subjectListError: '',
  bulkInsertData: {},
  bulkInsertLoading: false,
  bulkInsertSuccess: '',
  bulkInsertError: '',
  responseData: [],
}

export const schoolSeedingReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, (draft) => {
    switch (action.type) {
      case GET_SCHOOL_LIST:
        draft.schoolLoading = true
        draft.schoolList = []
        draft.schoolListError = ''
        break
      case GET_SCHOOL_LIST_SUCCESS:
        draft.schoolLoading = false
        draft.schoolList = action.data
        break
      case GET_SCHOOL_LIST_ERROR:
        draft.schoolLoading = false
        draft.schoolListError = action.error
        break
      case GET_SUBJECT_LIST:
        draft.schoolId = action.schoolId
        draft.subjectLoading = true
        draft.subjectList = []
        draft.subjectListError = ''
        break
      case GET_SUBJECT_LIST_SUCCESS:
        draft.subjectLoading = false
        draft.subjectList = action.data
        break
      case GET_SUBJECT_LIST_ERROR:
        draft.subjectLoading = false
        draft.subjectListError = action.error
        break
      case POST_BULK_INSERT:
        draft.bulkInsertLoading = true
        draft.bulkInsertData = action.data
        draft.bulkInsertSuccess = ''
        draft.bulkInsertError = ''
        draft.responseData = []
        break
      case POST_BULK_INSERT_RESPONSE:
        draft.bulkInsertLoading = false
        draft.bulkInsertSuccess = action.successMessage
        draft.bulkInsertError = action.errorMessage
        draft.responseData = action.responseData
        break
      case CLEAR_MESSAGE:
        draft.bulkInsertSuccess = ''
        draft.bulkInsertError = ''
        break
      default:
        return state
    }
  })
