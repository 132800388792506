import { all, fork } from 'redux-saga/effects'

import LoginSagas from 'containers/LoginPage/saga.js'
import * as ProfileSagas from 'containers/ProfilePage/saga.js'
import ChangePasswordSagas from 'containers/ChangePassword/saga.js'
import SchoolSeedingSagas from 'containers/SchoolSeeding/saga.js'
import LogoutSagas from 'containers/Logout/saga.js'

export default function* rootSaga() {
  yield all(
    [LoginSagas, ...Object.values(ProfileSagas), ChangePasswordSagas, SchoolSeedingSagas, LogoutSagas].map(fork),
  )
}
