import {
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_SUCCESS,
  CLEAR_LOGIN_MESSAGE,
  GET_PROFILE_INFO_ERROR,
  SET_AUTHENTICATION_RESPONSE,
  CHANGE_OTP_INFO,
  POST_OTP_CODE_ERROR,
  POST_OTP_CODE_SUCCESS,
  POST_OTP_CODE,
  POST_RESEND_OTP_ERROR,
  POST_RESEND_OTP_SUCCESS,
  POST_RESEND_OTP,
  CLEAR_RESEND_OTP,
  CLEAR_POST_OTP,
} from './constants'

export const initialState = {
  isLoading: false,
  isAuthenticated: localStorage.getItem('token') || false,
  token: localStorage.getItem('token'),
  loginError: '',
  emailAddress: '',
  profile: JSON.parse(localStorage.getItem('profile')),
  fileUploadData: false,
  authenticationResponse: null,
  OTPInfo: null,
  postOTPCodeLoading: false,
  postOTPCodeSuccess: '',
  postOTPCodeError: '',
  postResendOTPLoading: false,
  postResendOTPSuccess: '',
  postResendOTPError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        loginError: '',
        emailAddress: action.payload.username,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        token: action.payload.token,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        loginError: action.error,
      }

    case LOGOUT:
      return { ...state, isLoading: true, isAuthenticated: true }
    case LOGOUT_SUCCESS:
      return { ...state, isLoading: false, isAuthenticated: false, token: '' }

    case GET_PROFILE_INFO:
      return { ...state, isLoading: true }
    case GET_PROFILE_INFO_SUCCESS:
      return { ...state, profile: action.payload, isLoading: false }
    case GET_PROFILE_INFO_ERROR:
      return { ...state, isLoading: false }

    case SET_AUTHENTICATION_RESPONSE:
      return { ...state, authenticationResponse: action.payload }

    case CHANGE_OTP_INFO:
      return { ...state, OTPInfo: action.data }

    case POST_OTP_CODE:
      return { ...state, postOTPCodeLoading: true, postOTPCodeSuccess: '', postOTPCodeError: '' }
    case POST_OTP_CODE_SUCCESS:
      return { ...state, postOTPCodeLoading: false, postOTPCodeSuccess: action.payload }
    case POST_OTP_CODE_ERROR:
      return { ...state, postOTPCodeLoading: false, postOTPCodeError: action.error }

    case POST_RESEND_OTP:
      return { ...state, postResendOTPLoading: true, postResendOTPSuccess: '', postResendOTPError: '' }
    case POST_RESEND_OTP_SUCCESS:
      return { ...state, postResendOTPLoading: false, postResendOTPSuccess: action.payload }
    case POST_RESEND_OTP_ERROR:
      return { ...state, postResendOTPLoading: false, postResendOTPError: action.error }

    case CLEAR_RESEND_OTP:
      return { ...state, postResendOTPSuccess: '', postResendOTPError: '' }

    case CLEAR_POST_OTP:
      return { ...state, postOTPCodeSuccess: '', postOTPCodeError: '' }

    case CLEAR_LOGIN_MESSAGE:
      return {
        ...state,
        isLoading: false,
        loginError: '',
        OTPInfo: null,
        postOTPCodeLoading: false,
        postOTPCodeSuccess: '',
        postOTPCodeError: '',
        postResendOTPLoading: false,
        postResendOTPSuccess: '',
        postResendOTPError: '',
        emailAddress: '',
      }

    default:
      return state
  }
}
