import {
  GET_SCHOOL_LIST,
  GET_SCHOOL_LIST_SUCCESS,
  GET_SCHOOL_LIST_ERROR,
  GET_SUBJECT_LIST,
  GET_SUBJECT_LIST_SUCCESS,
  GET_SUBJECT_LIST_ERROR,
  POST_BULK_INSERT,
  POST_BULK_INSERT_RESPONSE,
  CLEAR_MESSAGE,
} from './constants'

export const getSchoolList = () => ({ type: GET_SCHOOL_LIST })
export const getSchoolListSuccess = (data) => ({
  type: GET_SCHOOL_LIST_SUCCESS,
  data,
})

export const getSchoolListError = (error) => ({
  type: GET_SCHOOL_LIST_ERROR,
  error,
})

export const getSubjectList = (schoolId) => ({ type: GET_SUBJECT_LIST, schoolId })
export const getSubjectListSuccess = (data) => ({
  type: GET_SUBJECT_LIST_SUCCESS,
  data,
})

export const getSubjectListError = (error) => ({
  type: GET_SUBJECT_LIST_ERROR,
  error,
})

export const postBulkInsert = (data) => ({
  type: POST_BULK_INSERT,
  data,
})

export const postBulkInsertResponse = (successMessage, errorMessage, responseData) => ({
  type: POST_BULK_INSERT_RESPONSE,
  successMessage,
  errorMessage,
  responseData,
})

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
})
