import { createSelector } from 'reselect'
import { initialState } from './reducer'

export const getGlobalData = (state) => state.LoginReducer

export const selectLogin = ({ LoginReducer }) => LoginReducer || initialState

export const makeSelectAuthenticationResponse = () =>
  createSelector(selectLogin, ({ authenticationResponse }) => authenticationResponse)

export const makeSelectOTPInfo = () => createSelector(selectLogin, ({ OTPInfo }) => OTPInfo)

export const makeSelectPostOTPCodeLoading = () =>
  createSelector(selectLogin, ({ postOTPCodeLoading }) => postOTPCodeLoading)
export const makeSelectPostOTPCodeSuccess = () =>
  createSelector(selectLogin, ({ postOTPCodeSuccess }) => postOTPCodeSuccess)
export const makeSelectPostOTPCodeError = () => createSelector(selectLogin, ({ postOTPCodeError }) => postOTPCodeError)

export const makeSelectPostResendOTPLoading = () =>
  createSelector(selectLogin, ({ postResendOTPLoading }) => postResendOTPLoading)
export const makeSelectPostResendOTPSuccess = () =>
  createSelector(selectLogin, ({ postResendOTPSuccess }) => postResendOTPSuccess)
export const makeSelectPostResendOTPError = () =>
  createSelector(selectLogin, ({ postResendOTPError }) => postResendOTPError)

export const makeSelectEmail = () => createSelector(selectLogin, ({ emailAddress }) => emailAddress)
