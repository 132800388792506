import { createSelector } from 'reselect'
import { initialState } from './reducer'

const schoolSeedingState = ({ schoolSeedingReducer }) => schoolSeedingReducer || initialState

const createMakeSelector = (key) => () => createSelector(schoolSeedingState, (state) => state[key])

export const makeSelectSchoolListLoading = createMakeSelector('schoolLoading')
export const makeSelectSchoolList = createMakeSelector('schoolList')
export const makeSelectSchoolListError = createMakeSelector('schoolListError')
export const makeSelectSchoolId = createMakeSelector('schoolId')
export const makeSelectSubjectListLoading = createMakeSelector('subjectLoading')
export const makeSelectSubjectList = createMakeSelector('subjectList')
export const makeSelectSubjectListError = createMakeSelector('subjectListError')
export const makeSelectBulkInsertData = createMakeSelector('bulkInsertData')
export const makeSelectBulkInsertLoading = createMakeSelector('bulkInsertLoading')
export const makeSelectBulkInsertDataSuccessMessage = createMakeSelector('bulkInsertSuccess')
export const makeSelectBulkInsertDataErrorMessage = createMakeSelector('bulkInsertError')
export const makeSelectBulkInsertResponseDataList = createMakeSelector('responseData')
