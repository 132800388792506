import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/Button'

const ContentDialog = (props) => {
  const {
    id,
    primaryText,
    content,
    isOpen,
    onConfirm,
    onClose,
    confirmLabel,
    cancelLabel,
    maxWidth = 'xs',
    noConfirmLabel,
    noCancelLabel,
    confirmLabelDisabled,
    onBackdropClick,
  } = props

  return (
    <div>
      <Dialog
        id={id}
        open={isOpen}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={maxWidth}
        fullWidth
        disableBackdropClick
        onBackdropClick={onBackdropClick}
      >
        <DialogTitle id='alert-dialog-title'>{primaryText}</DialogTitle>
        {content && <DialogContent>{content}</DialogContent>}
        <DialogActions>
          {!noCancelLabel && (
            <Button autoFocus onClick={onClose} color='primary'>
              {cancelLabel || 'Cancel'}
            </Button>
          )}
          {!noConfirmLabel && (
            <Button onClick={onConfirm} color='primary' disabled={confirmLabelDisabled}>
              {confirmLabel || 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

ContentDialog.propTypes = {
  id: PropTypes.string,
  primaryText: PropTypes.string,
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  maxWidth: PropTypes.string,
  noConfirmLabel: PropTypes.bool,
  noCancelLabel: PropTypes.bool,
  confirmLabelDisabled: PropTypes.bool,
  onBackdropClick: PropTypes.func,
}

export default ContentDialog
