import React, { useState } from 'react'

import PropTypes from 'prop-types'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const DatePicker = ({ id, label, minDate, initialDate, onDateChange, ...rest }) => {
  const [selectedDate, setSelectedDate] = useState(initialDate)

  const handleDateChange = (date) => {
    setSelectedDate(date)
    onDateChange(id, date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        format='MM/dd/yyyy'
        margin='normal'
        id={id}
        label={label}
        value={selectedDate}
        minDate={minDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  initialDate: PropTypes.instanceOf(Date),
}

export default DatePicker
