import { combineReducers } from 'redux'

import LoginReducer from 'containers/LoginPage/reducer.js'
import ProfileReducer from 'containers/ProfilePage/reducer.js'
import ChangePasswordReducer from 'containers/ChangePassword/reducer.js'
import { schoolSeedingReducer } from 'containers/SchoolSeeding/reducer.js'

export default combineReducers({
  LoginReducer,
  ProfileReducer,
  ChangePasswordReducer,
  schoolSeedingReducer,
})
