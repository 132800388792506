export const GET_SCHOOL_LIST = 'SchoolSeeding/GET_SCHOOL_LIST'
export const GET_SCHOOL_LIST_SUCCESS = 'SchoolSeeding/GET_SCHOOL_LIST_SUCCESS'
export const GET_SCHOOL_LIST_ERROR = 'SchoolSeeding/GET_SCHOOL_LIST_ERROR'

export const GET_SUBJECT_LIST = 'SchoolSeeding/GET_SUBJECT_LIST'
export const GET_SUBJECT_LIST_SUCCESS = 'SchoolSeeding/GET_SUBJECT_LIST_SUCCESS'
export const GET_SUBJECT_LIST_ERROR = 'SchoolSeeding/GET_SUBJECT_LIST_ERROR'

export const POST_BULK_INSERT = 'SchoolSeeding/POST_BULK_INSERT'
export const POST_BULK_INSERT_RESPONSE = 'SchoolSeeding/POST_BULK_INSERT_RESPONSE'

export const CLEAR_MESSAGE = 'SchoolSeeding/CLEAR_MESSAGE'
