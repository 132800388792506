import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  apCustomOTPInput: {
    width: '2.5rem !important',
    padding: '10px 0',
    outline: 'none !important',
    fontWeight: 600,
    fontSize: '20px',
    border: '1px solid #ebebeb',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    transitionDuration: '0.3s',
    color: '#212529',
    "&:not($apCustomOTPError):not([value='']), apCustomOTPFocus": {
      border: '1px solid #3f8efc',
      borderRadius: '4px',
      boxShadow: ' 0 4px 0 0 #ccdafc !important',
      transform: 'translateY(-5px)',
    },
  },
  apCustomOTPError: {
    color: '#f15366',
    border: '1px solid #f15366',
    boxShadow: ' 0 4px 0 0 #f153664d !important',
  },
  apCustomOTPErrorMessage: {
    color: '#f15366',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
    margin: '16px 0 0',
  },
}))

export default useStyles
