import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

const formatReportData = (data) =>
  data.map((person) => ({
    Name: person.name,
    ClassName: person.className.join(', '),
    Username: person.userName,
    Password: person.password,
    SchoolName: person.schoolName,
    ActiveBookNames: person.activeBookName.join(', '),
    Remarks: person.remark,
  }))

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

const exportExcel = (data, filename) => {
  const formattedData = formatReportData(data)
  const ws = XLSX.utils.json_to_sheet(formattedData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const file = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(file, filename + fileExtension)
}

export default exportExcel
