import { call, put, takeLatest, select } from 'redux-saga/effects'
import request from 'utils/request'

import { GET_SCHOOL_LIST, GET_SUBJECT_LIST, POST_BULK_INSERT } from './constants'
import {
  getSchoolListSuccess,
  getSchoolListError,
  getSubjectListSuccess,
  getSubjectListError,
  postBulkInsertResponse,
} from './actions'
import { makeSelectBulkInsertData, makeSelectSchoolId } from './selector'

const getToken = localStorage.getItem('token')

function* getSchoolList() {
  const token = getToken
  try {
    const response = yield call(request, '/api/schools', {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
    const schoolList = response.result.items

    yield put(getSchoolListSuccess(schoolList))
  } catch (error) {
    yield put(getSchoolListError(error))
  }
}

function* getSubjectList() {
  const token = getToken
  const schoolId = yield select(makeSelectSchoolId())
  try {
    const response = yield call(request, `/api/school/${schoolId}/subjects?assetType=ebook`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
    const subjectListData = response.result

    yield put(getSubjectListSuccess(subjectListData))
  } catch (error) {
    yield put(getSubjectListError(error))
  }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

function* postBulkInsert() {
  try {
    const token = getToken
    const postBulkInsertData = yield select(makeSelectBulkInsertData())
    const { classes, schoolName, subject, students, teachers } = postBulkInsertData
    let successMessage = ''
    let errorMessage = ''
    const responseData = []
    const studentsLength = students.length
    const teachersLength = teachers.length
    const userLabel = studentsLength ? 'Student' : 'Teacher'
    const userLength = studentsLength || teachersLength

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < userLength; i++) {
      const singUserData = studentsLength ? postBulkInsertData.students[i] : postBulkInsertData.teachers[i]

      const insertData = {
        schoolName,
        subject,
        classes,
        students: studentsLength ? [singUserData] : [],
        teachers: teachersLength ? [singUserData] : [],
      }

      try {
        const response = yield call(request, '/api/bulk-ebook-import', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(insertData),
        })

        let responseUserData

        if (studentsLength) {
          responseUserData = response?.result?.students && response?.result?.students[0]
        } else {
          responseUserData = response?.result?.teachers && response?.result?.teachers[0]
        }
        const { activeBookName } = responseUserData?.eBookClassDetails[0]

        responseData.push({
          ...singUserData,
          activeBookName,
          schoolName,
          hasError: false,
          remark: `${userLabel} created for class ${singUserData.className[0]}`,
        })
        successMessage = `${userLabel}(s) registration completed`
        yield sleep(1000) // Give server a breather
      } catch ({ response }) {
        let remark

        if (response && response.json) {
          const errorObj = yield response.json()

          if (errorObj && errorObj.error) {
            remark = errorObj.error.message
          }
        }

        successMessage = ''
        errorMessage = `${userLabel}(s) registration failed`
        responseData.push({ ...singUserData, activeBookName: ['N/A'], hasError: true, remark })
      }
    }
    responseData.sort((a, b) => (a.id > b.id ? 1 : -1))

    yield put(postBulkInsertResponse(successMessage, errorMessage, responseData))
  } catch (error) {
    const errorObj = yield error.response.json()
    const successMessage = ''
    const errorMessage = errorObj.message
    yield put(postBulkInsertResponse(successMessage, errorMessage, []))
  }
}

export default function* SchoolSeedingSagas() {
  yield takeLatest(GET_SCHOOL_LIST, getSchoolList)
  yield takeLatest(GET_SUBJECT_LIST, getSubjectList)
  yield takeLatest(POST_BULK_INSERT, postBulkInsert)
}
