import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from './constants'

export const logout = () => ({ type: LOGOUT })

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const logoutError = () => ({
  type: LOGOUT_ERROR,
})
