import React from 'react'
import PropTypes from 'prop-types'

import { format } from 'date-fns'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'

import CustomTable from 'components/CustomTable'

import useStyles from '../style'

const SeedingDataTable = (props) => {
  const classes = useStyles()
  const { data } = props
  const { classes: classDetail, schoolName, students, subject, teachers } = data
  const startDate = format(new Date(classDetail[0].startDate), 'd MMMM yyyy')
  const endDate = format(new Date(classDetail[0].endDate), 'd MMMM yyyy')

  const isStudentSeed = students.length || false

  const columns = [
    { title: 'Username', field: 'userName' },
    { title: `${isStudentSeed ? 'Student' : 'Teacher'} Name`, field: 'name' },
    { title: 'Password', field: 'password' },
    {
      title: 'Class Names',
      field: 'className',
      editable: 'never',
      render: (rowData) => rowData.className.map((m) => <Chip key={m} variant='outlined' label={m} />),
    },
  ]

  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <Typography variant='h6' className={classes.paper}>
            {`Total ${isStudentSeed ? `Students:  ${students.length}` : `Teachers:  ${teachers.length}`} `}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h6' className={classes.paper}>
            {`School Name: ${schoolName}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h6' className={classes.paper}>
            {`Subject Name: ${subject}`}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant='h6' className={classes.paper}>
            {`Class Name: ${classDetail[0].name}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6' className={classes.paper}>
            <div>Class StartDate:</div>
            <div>{startDate}</div>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6' className={classes.paper}>
            <div>Class EndDate:</div>
            <div>{endDate}</div>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h6' className={classes.paper}>
            {`Class isFreemium: ${classDetail[0].isFreemium}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            title='Confirmation Table'
            columns={columns}
            data={isStudentSeed ? students : teachers}
            pageSize={10}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

SeedingDataTable.propTypes = {
  data: PropTypes.object,
}

export default SeedingDataTable
