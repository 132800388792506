import request from 'utils/request'
// import { replace } from 'connected-react-router'

import { call, put, takeLatest } from 'redux-saga/effects'
import { LOGOUT } from './constants'
import { logoutSuccess, logoutError } from './actions'

const resetLocalStorage = () => {
  localStorage.removeItem('profile')
  localStorage.removeItem('token')
  localStorage.removeItem('schoolSeedingData')
  window.location.replace('/login')
}

export function* postLogout() {
  try {
    const token = localStorage.getItem('token')

    if (token) {
      yield call(request, '/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      yield put(logoutSuccess())
    }
    resetLocalStorage()
  } catch (error) {
    yield put(logoutError())
    resetLocalStorage()
  }
}

export default function* logoutPageSaga() {
  yield takeLatest(LOGOUT, postLogout)
}
