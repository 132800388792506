import React from 'react'

import PropTypes from 'prop-types'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const CustomSwitch = ({ id, label, checked, onChange, ...rest }) => {
  const handleChange = (event) => {
    onChange(event.target.name, event.target.checked)
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} name={id} color='primary' {...rest} />}
        label={label}
      />
    </FormGroup>
  )
}

CustomSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CustomSwitch
