import { call, put, takeLatest, select } from 'redux-saga/effects'
import request from 'utils/request'
import decode from 'jwt-decode'

import { LOGIN, POST_OTP_CODE, POST_RESEND_OTP } from './constants'
import {
  loginSuccess,
  loginError,
  getProfileInfoSuccess,
  getProfileInfoError,
  setAuthenticationResponse,
  changeOTPInfo,
  postOTPCodeSuccess,
  postOTPCodeError,
  postResendOTPError,
  postResendOTPSuccess,
} from './actions'

import { getGlobalData, makeSelectAuthenticationResponse, makeSelectOTPInfo } from './selectors'

function* login({ payload }) {
  try {
    const loginCredentials = {
      userNameOrEmailAddress: payload.username,
      password: payload.password,
      reCaptchaToken: null,
      tenancyName: 'Default',
    }
    const response = yield call(request, '/api/TokenAuth/Authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginCredentials),
    })
    if (response.success) {
      if (response.result.accessToken) {
        yield put(setAuthenticationResponse(response.result))
        yield call(verifyAuthenticationProfile)
      } else {
        const { encryptedCode, userId } = response.result
        yield put(changeOTPInfo({ encryptedCode, userId }))
      }
    }
  } catch (error) {
    const errorObj = yield error.response.json()
    yield put(loginError(errorObj.error.message))
  }
}

export function* verifyAuthenticationProfile() {
  const authenticationRes = yield select(makeSelectAuthenticationResponse())
  const token = authenticationRes.accessToken
  localStorage.setItem('token', token)

  const roleUrl = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  const role = decode(token)[roleUrl]

  if (role === 'Admin') {
    // yield call(recieveAdminInfo)
    // yield call(recieveCountryListData)

    yield* getProfileData(token)
    yield put(loginSuccess(authenticationRes))

    yield put(window.location.replace('/'))
  }
}

export function* getProfileData(token) {
  // call for profile

  try {
    const response = yield call(request, '/api/admin/profile', {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })

    localStorage.setItem('profile', JSON.stringify(response.result))
    yield put(getProfileInfoSuccess(response.result))
    yield put(loginSuccess({ token }))
  } catch (error) {
    yield put(getProfileInfoError(error))
  }
}

export function* uploadFile() {
  try {
    const { fileUploadData } = yield select(getGlobalData)
    const token = localStorage.getItem('token')
    const response = yield call(request, '/upload-file', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: fileUploadData,
    })
    return response.url
  } catch (error) {
    return 'failed'
  }
}

export function* postOtpCode({ payload }) {
  const otpInfo = yield select(makeSelectOTPInfo())

  const { userId } = otpInfo
  const { otpCode } = payload
  const couponCode = localStorage.getItem('coupon') || ''

  try {
    const { result } = yield call(request, '/api/authenticate-otp', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId,
        otp: otpCode,
        couponCode,
      }),
    })
    yield put(setAuthenticationResponse(result))
    yield call(verifyAuthenticationProfile)
    yield put(postOTPCodeSuccess('success'))
  } catch (error) {
    const errorObj = yield error.response.json()
    yield put(postOTPCodeError(errorObj.error.message))
  }
}

export function* postResendOtp() {
  const otpInfo = yield select(makeSelectOTPInfo())
  try {
    const { result } = yield call(request, '/api/generate-otp', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(otpInfo),
    })
    yield put(changeOTPInfo(result))
    const successMessage = 'OTP Code has been sent. Please, Check your email.'
    yield put(postResendOTPSuccess(successMessage))
  } catch (error) {
    const errorObj = yield error.response.json()
    const errMsg = errorObj.error.message
    yield put(postResendOTPError(errMsg))
  }
}

export default function* mySaga() {
  yield takeLatest(LOGIN, login)
  yield takeLatest(POST_OTP_CODE, postOtpCode)
  yield takeLatest(POST_RESEND_OTP, postResendOtp)
  // yield takeLatest(GET_PROFILE_INFO, getProfileData);
}
