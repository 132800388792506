import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import CloudUpload from '@material-ui/icons/CloudUpload'
import LinearProgress from '@material-ui/core/LinearProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Check from '@material-ui/icons/Check'
import Edit from '@material-ui/icons/Edit'

import TextField from 'components/TextField'
import Button from 'components/Button'
import ConfirmationModal from 'components/ConfirmationModal'
import ContentDialog from 'components/ContentDialog'
import { Wrapper } from 'components/Common'
import { HorizontalTabs, VerticalTabs } from 'components/CustomTabs'
import CustomTable from 'components/CustomTable'
import CustomSelectField from 'components/CustomSelectField'
import DatePicker from 'components/DatePicker'

import parseExcel from 'utils/parseExcel'
import { useSnackbar } from 'notistack'
import produce from 'immer'

import exportExcel from 'utils/exportExcel'
import renameKey from 'utils/renameObjectKey'
import validateEmail from 'utils/validateEmail'

import SeedingDataTable from './Components/SeedingDataTable'
import ResponseToExcel from './Components/ResponseToExcel'
import ClassDetail from './Components/ClassDetail'

import useStyles from './style'
import { getSchoolList, getSubjectList, postBulkInsert, clearMessage } from './actions'
import {
  makeSelectSchoolList,
  makeSelectSchoolListLoading,
  makeSelectSubjectListLoading,
  makeSelectSubjectList,
  makeSelectBulkInsertDataSuccessMessage,
  makeSelectBulkInsertDataErrorMessage,
  makeSelectBulkInsertLoading,
  makeSelectBulkInsertResponseDataList,
} from './selector'

const SchoolSeeding = (props) => {
  const {
    fetchSchoolList,
    fetchSubjectList,
    schoolListLoading,
    schoolList,
    subjectListLoading,
    subjectList,
    saveSeedSchoolData,
    bulkInsertLoading,
    bulkInsertSuccessMessage,
    bulkInsertErrorMessage,
    responseDataList,
    cleanMessage,
  } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const studentColumn = [
    {
      title: 'id',
      field: 'id',
      editable: 'never',
      hidden: true,
    },
    { title: 'Username', field: 'userName' },
    { title: 'Student Name', field: 'studentName' },
    { title: 'Password', field: 'studentPassword' },
    {
      title: 'Class Names',
      field: 'className',
      editable: 'never',
      render: (rowData) => rowData.className.map((m) => <Chip key={m} variant='outlined' label={m} />),
    },
    { title: 'Remark', field: 'remark', editable: 'never', defaultSort: 'desc' },
  ]

  const teacherColumn = [
    {
      title: 'id',
      field: 'id',
      editable: 'never',
      hidden: true,
    },
    { title: 'Username', field: 'userName' },
    { title: 'Teacher Name', field: 'teacherName' },
    { title: 'Password', field: 'teacherPassword' },
    {
      title: 'Class Names',
      field: 'className',
      editable: 'never',
      render: (rowData) => rowData.className.map((m) => <Chip key={m} variant='outlined' label={m} />),
    },
    { title: 'Remark', field: 'remark', editable: 'never', defaultSort: 'desc' },
  ]

  const [parsedExcelData, setParsedExcelData] = useState()
  const [loading, setLoading] = useState()

  const [subjectState, setSubjectState] = useState({
    school: '',
    subject: '',
    startDate: new Date(),
    endDate: new Date(),
    isFreemium: false,
  })

  const [showModal, setShowModal] = useState({
    confirmModalActive: false,
    showUpdateConfirmation: false,
    showDeleteConfirmation: false,
    showVerificationData: false,
    showResetConfirmation: false,
    showResponseData: false,
    confirmEditClassName: false,
    confirmEditClassDisplayName: false,
  })

  const [tempData, setTempData] = useState({})

  const [seedingData, setSeedingData] = useState()
  const [fileName, setFileName] = useState([])
  const [upload, setUpload] = useState(false)
  const [editPassword, setEditPassword] = useState({
    studentPassword: true,
    teacherPassword: true,
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [password, setPassword] = useState({
    studentPassword: '',
    teacherPassword: '',
  })
  const [tabIndexValue, setTabIndexValue] = useState({
    classTabIndex: 0,
    listTabIndex: 0,
  })
  const [schoolListState, setSchoolListState] = useState([])
  const [subjectListState, setSubjectListState] = useState([])
  const [initialSubjectState, setInitialSubjectState] = useState()
  const [initialSchoolState, setInitialSchoolState] = useState()
  const [dataAfterResponse, setDataAfterResponse] = useState()

  // starting point where initial values are set to state and subject and school list api are called
  useEffect(() => {
    // school and subject listing

    fetchSchoolList()

    // get state from persisted data
    const persistedData = localStorage.getItem('schoolSeedingData')
    if (persistedData) {
      const parsedData = JSON.parse(persistedData)
      const startDate = new Date(parsedData.subjectState.startDate)
      const endDate = new Date(parsedData.subjectState.endDate)
      const formattedSubjectData = { ...parsedData.subjectState, startDate, endDate }

      setFileName(parsedData.fileName)
      setParsedExcelData(parsedData.parsedExcelData)
      setUpload(true)
      setPassword(parsedData.password)
      setSubjectState(formattedSubjectData)
      setEditPassword({
        studentPassword: false,
        teacherPassword: false,
      })
    }
  }, [fetchSchoolList, fetchSubjectList])

  // error and success handler for bulk insert
  useEffect(() => {
    if (bulkInsertErrorMessage) setErrorMessage(bulkInsertErrorMessage)
    if (bulkInsertSuccessMessage) setSuccessMessage(bulkInsertSuccessMessage)
  }, [bulkInsertSuccessMessage, bulkInsertErrorMessage])

  // error handler
  useEffect(() => {
    if (errorMessage || successMessage) {
      enqueueSnackbar(errorMessage || successMessage, {
        variant: successMessage ? 'success' : 'error',
        onClose: () => {
          setSuccessMessage('')
          setErrorMessage('')
          cleanMessage()
        },
      })

      setShowModal((prevState) => ({
        ...prevState,
        confirmModalActive: false,
      }))
    }
  }, [cleanMessage, enqueueSnackbar, errorMessage, successMessage])

  // format school list for drop down list
  useEffect(() => {
    const managedSchoolList = (schoolList || []).map((school) => ({
      id: school.id,
      label: school.name,
    }))

    const initialSchool = schoolList.find((school) => school.id === subjectState.school)

    setSchoolListState(managedSchoolList)
    if (initialSchool) {
      setInitialSchoolState({ id: initialSchool.id, label: initialSchool.name })

      fetchSubjectList(initialSchool.id)
    }
  }, [fetchSubjectList, schoolList, schoolListLoading, subjectState.school])

  // format subject list for drop down list
  useEffect(() => {
    const managedSubjectList = (subjectList || []).map((subject) => ({
      id: subject.id,
      label: subject.name,
    }))

    if (!subjectListLoading) setSubjectListState(managedSubjectList)

    const classSubjectDetail = parsedExcelData?.classList[tabIndexValue.classTabIndex]?.subject || undefined
    setInitialSubjectState(classSubjectDetail)
  }, [parsedExcelData, subjectList, subjectListLoading, tabIndexValue.classTabIndex])

  // update persisted data on change
  useEffect(() => {
    if (upload && parsedExcelData && typeof parsedExcelData !== 'undefined') {
      localStorage.setItem('schoolSeedingData', JSON.stringify({ parsedExcelData, subjectState, password, fileName }))
    }
  }, [upload, fileName, parsedExcelData, password, subjectState])

  // reformat error list with seeded value for excel sheet data and response to excel component
  useEffect(() => {
    if (!bulkInsertLoading && seedingData) {
      const { students } = seedingData
      const isStudentSeed = students.length
      let studentsArr = []
      let teachersArr = []

      if (isStudentSeed) {
        studentsArr = [...responseDataList]
      } else {
        teachersArr = [...responseDataList]
      }

      const formattedData = {
        ...seedingData,
        students: studentsArr,
        teachers: teachersArr,
      }

      setDataAfterResponse(formattedData)
    }
  }, [responseDataList, seedingData, bulkInsertLoading])

  // teacher and students array
  const studentsObject = parsedExcelData?.students
  const parsedStudentsArray = parsedExcelData?.students
    ? Object.values(studentsObject)[tabIndexValue.classTabIndex]
    : []
  const teachersObject = parsedExcelData?.teachers
  const parsedTeachersArray = parsedExcelData?.teachers
    ? Object.values(teachersObject)[tabIndexValue.classTabIndex]
    : []
  const currentTabStudents = studentsObject ? Object.values(studentsObject)[tabIndexValue.classTabIndex] : []
  const currentTabTeachers = teachersObject ? Object.values(teachersObject)[tabIndexValue.classTabIndex] : []
  const currentClassDetail =
    parsedExcelData?.classList?.length && parsedExcelData.classList[tabIndexValue.classTabIndex]

  // handle row update from custom table component
  const handleRowUpdate = (newData, oldData) => {
    let index
    setSuccessMessage('Row updated successfully')
    if (tabIndexValue.listTabIndex === 0) {
      const students = parsedExcelData.students[newData.className[0]]
      index = students.findIndex((student) => student.id === oldData.id)
      const student = students.find((st) => st.id === oldData.id)
      let updatedData = { ...newData, id: student.id, remark: '' }
      const similarEmailExist = students.find((st) => st.userName === updatedData.userName && st.id !== updatedData.id)

      if (similarEmailExist) {
        updatedData = { ...newData, id: student.id, remark: 'Duplicate Email' }
      }

      const isEmailValid = validateEmail(updatedData.userName)
      if (!isEmailValid) updatedData = { ...newData, id: student.id, remark: 'Email not valid' }

      const updatedStudents = produce(students, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[index] = updatedData
      })

      setParsedExcelData((prevState) => ({
        ...prevState,
        students: { ...parsedExcelData.students, [newData.className[0]]: updatedStudents },
      }))
    } else {
      const teachers = parsedExcelData.teachers[newData.className[0]]
      index = teachers.findIndex((teacher) => teacher.id === oldData.id)
      const teacher = teachers.find((st) => st.id === oldData.id)
      let updatedData = { ...newData, id: teacher.id, remark: '' }

      const similarEmailExist = teachers.find(
        (teach) => teach.userName === updatedData.userName && teach.id !== updatedData.id,
      )

      if (similarEmailExist) {
        updatedData = { ...newData, id: teacher.id, remark: 'Duplicate Email' }
      }

      const isEmailValid = validateEmail(updatedData.userName)
      if (!isEmailValid) updatedData = { ...newData, id: teacher.id, remark: 'Email not valid' }

      if (index > -1) {
        const updatedTeachers = produce(teachers, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft[index] = updatedData
        })
        setParsedExcelData((prevState) => ({
          ...prevState,
          teachers: { ...parsedExcelData.teachers, [newData.className[0]]: updatedTeachers },
        }))
      }
    }
  }

  const handleDeleteRow = (oldData) => {
    let index
    setSuccessMessage('Row deleted successfully')
    if (tabIndexValue.listTabIndex === 0) {
      const students = [...parsedExcelData.students[oldData.className[0]]]
      index = students.findIndex((student) => student.id === oldData.id)
      students.splice(index, 1)
      setParsedExcelData((prevState) => ({
        ...prevState,
        students: { ...parsedExcelData.students, [oldData.className[0]]: students },
      }))
    } else {
      const teachers = [...parsedExcelData.teachers[oldData.className[0]]]
      index = teachers.findIndex((student) => student.id === oldData.id)
      teachers.splice(index, 1)
      setParsedExcelData((prevState) => ({
        ...prevState,
        teachers: { ...parsedExcelData.teachers, [oldData.className[0]]: teachers },
      }))
    }
  }

  // function called after upload seed is clicked, spread operator for setting passwords and class object
  const handleUploadSeedData = () => {
    const classesArr = parsedExcelData.classList

    // insert password in each student
    for (const key in studentsObject) {
      if ({}.hasOwnProperty.call(studentsObject, key)) {
        studentsObject[key].forEach((student) => {
          const studentVal = student
          studentVal.studentPassword = password.studentPassword
          return studentVal
        })
      }
    }
    for (const key in teachersObject) {
      if ({}.hasOwnProperty.call(teachersObject, key)) {
        teachersObject[key].forEach((teacher) => {
          const teacherVal = teacher
          teacherVal.teacherPassword = password.teacherPassword
          return teacherVal
        })
      }
    }
    const additionalClassDetails = classesArr.map((classe) => ({
      name: classe?.name,
      startDate: subjectState.startDate,
      endDate: subjectState.endDate,
      isFreemium: subjectState.isFreemium,
      displayName: classe?.displayName,
    }))

    setParsedExcelData((prevState) => ({
      ...prevState,
      classList: additionalClassDetails,
      students: studentsObject,
      teachers: teachersObject,
    }))

    setUpload(true)
    setEditPassword({
      studentPassword: false,
      teacherPassword: false,
    })
  }

  const handleSubjectStateChanges = (id, selectValues) => {
    if (id === 'isFreemium') {
      setSubjectState((prevState) => ({
        ...prevState,
        [id]: selectValues,
      }))
    } else if (id === 'startDate' || id === 'endDate') {
      setSubjectState((prevState) => ({
        ...prevState,
        [id]: new Date(selectValues),
      }))
      const updatedClassList = parsedExcelData.classList.map((classe) => {
        const prevData = { ...classe }
        return { ...prevData, [id]: new Date(selectValues) }
      })

      setParsedExcelData((prevState) => ({
        ...prevState,
        classList: updatedClassList,
      }))
    } else {
      setSubjectState((prevState) => ({
        ...prevState,
        [id]: selectValues?.id,
      }))

      const classLists = [...parsedExcelData.classList]
      const updatedClass = { ...currentClassDetail, [id]: selectValues }

      const index = classLists.findIndex((classe) => classe.name === currentClassDetail?.name)

      const updatedClassList = produce(classLists, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[index] = updatedClass
      })

      setParsedExcelData((prevState) => ({
        ...prevState,
        classList: updatedClassList,
      }))
    }
  }

  const handlePasswordChange = (id, value) => {
    setPassword((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const cancelSeeding = () => {
    setUpload(false)
    setParsedExcelData()
    setSubjectState({
      school: '',
      subject: '',
      startDate: new Date(),
      endDate: new Date(),
      isFreemium: false,
    })
    setPassword({
      studentPassword: '',
      teacherPassword: '',
    })
    setEditPassword({
      studentPassword: true,
      teacherPassword: true,
    })
    setInitialSchoolState()
    setInitialSubjectState()
    setFileName([])

    setShowModal((prevState) => ({
      ...prevState,
      showResetConfirmation: false,
    }))
    localStorage.removeItem('schoolSeedingData')
  }

  const handleSeedIndividualClass = () => {
    const findSchool = schoolList.find((school) => school.id === subjectState.school)
    const findSubject = subjectList.find((subject) => subject.id === subjectState.subject)
    const students = studentsObject ? Object.values(studentsObject)[tabIndexValue.classTabIndex] : []
    const teachers = teachersObject ? Object.values(teachersObject)[tabIndexValue.classTabIndex] : []
    const classArray = []
    classArray.push(currentClassDetail)
    let formattedStudentData = []
    let formattedTeacherData = []

    if (tabIndexValue.listTabIndex === 0) {
      if (students.length) {
        formattedStudentData = students.map((student) => {
          const studentData = { ...student }
          const { studentPassword, studentName } = studentData
          delete studentData.studentName
          delete studentData.studentPassword
          delete studentData.id
          delete studentData.remark

          const formattedData = {
            ...studentData,
            name: studentName,
            password: studentPassword,
          }

          return formattedData
        })
      }
    } else {
      formattedTeacherData = teachers.map((teacher) => {
        const teacherData = { ...teacher }
        const { teacherPassword, teacherName } = teacherData
        delete teacherData.teacherName
        delete teacherData.teacherPassword
        delete teacherData.remark

        const formattedData = {
          ...teacherData,
          name: teacherName,
          password: teacherPassword,
        }

        return formattedData
      })
    }

    const formattedData = {
      schoolName: findSchool?.name,
      subject: findSubject?.name,
      classes: classArray,
      students: formattedStudentData,
      teachers: formattedTeacherData,
    }

    setShowModal((prevState) => ({
      ...prevState,
      showVerificationData: true,
    }))

    setSeedingData(formattedData)
    // saveSeedSchoolData(formattedData)
  }

  const handleClassDetailChanges = (id, val) => {
    if (id === 'className') {
      setShowModal((prevState) => ({
        ...prevState,
        confirmEditClassName: true,
      }))
    } else if (id === 'displayName') {
      setShowModal((prevState) => ({
        ...prevState,
        confirmEditClassDisplayName: true,
      }))
    } else if (id === 'isFreemium') {
      setSubjectState((prevState) => ({
        ...prevState,
        [id]: val,
      }))

      const classLists = [...parsedExcelData.classList]
      const updateClassFreemium = { ...currentClassDetail, isFreemium: val }

      const index = classLists.findIndex((classe) => classe.name === currentClassDetail?.name)

      const updatedClassList = produce(classLists, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[index] = updateClassFreemium
      })

      setParsedExcelData((prevState) => ({
        ...prevState,
        classList: updatedClassList,
      }))
    } else if (id === 'subject') {
      handleSubjectStateChanges(id, val)
    }

    setTempData((prevState) => ({
      ...prevState,
      [id]: val,
    }))
  }

  // tab rendering starts from here

  const horizontalTabContent = {
    value: tabIndexValue.listTabIndex,
    handleChange: (event, newValue) =>
      setTabIndexValue((prevState) => ({
        ...prevState,
        listTabIndex: newValue,
      })),
    items: [
      {
        labelText: 'Students List',
        content: (
          <CustomTable
            columns={studentColumn}
            data={parsedStudentsArray}
            updatable
            deletable
            onRowUpdate={handleRowUpdate}
            onRowDelete={handleDeleteRow}
            rowStyleOption={(rowData) => ({
              backgroundColor: rowData.remark && '#CF6679',
            })}
          />
        ),
      },
      {
        labelText: 'Teachers List',
        content: (
          <CustomTable
            columns={teacherColumn}
            data={parsedTeachersArray}
            updatable
            deletable
            onRowUpdate={handleRowUpdate}
            onRowDelete={handleDeleteRow}
            rowStyleOption={(rowData) => ({
              backgroundColor: rowData.remark && '#CF6679',
            })}
          />
        ),
      },
    ],
  }

  const verticalTabItems = () => {
    const items = []

    for (const key in studentsObject) {
      if ({}.hasOwnProperty.call(studentsObject, key)) {
        const labelText = key
        items.push({
          labelText,
          content: (
            <div>
              <Paper className={classes.paper} style={{ marginBottom: 20 }}>
                <ClassDetail
                  classDetails={currentClassDetail}
                  subjectList={subjectListState}
                  initialSubject={initialSubjectState}
                  currentTab={tabIndexValue.listTabIndex}
                  studentsLength={currentTabStudents.length || 0}
                  teachersLength={currentTabTeachers.length || 0}
                  disableSeedButton={
                    !(
                      upload &&
                      parsedExcelData &&
                      subjectState.school &&
                      subjectState.subject &&
                      subjectState.startDate &&
                      subjectState.endDate
                    )
                  }
                  onChange={(id, val) => handleClassDetailChanges(id, val)}
                  onSeedButtonClick={() => {
                    setShowModal((prevState) => ({
                      ...prevState,
                      confirmModalActive: true,
                    }))
                  }}
                />
              </Paper>

              <HorizontalTabs {...horizontalTabContent} />
            </div>
          ),
        })
      }
    }
    return items
  }

  const verticalTabContent = {
    value: tabIndexValue.classTabIndex,
    handleChange: (event, newValue) => {
      const classSubjectDetail = parsedExcelData?.classList[newValue]?.subject || undefined
      setInitialSubjectState(classSubjectDetail)
      setSubjectState((prevState) => ({
        ...prevState,
        subject: classSubjectDetail ? classSubjectDetail?.id : '',
      }))

      setTabIndexValue((prevState) => ({
        ...prevState,
        classTabIndex: newValue,
      }))
    },

    items: verticalTabItems(),
  }

  // tab rendering ends from here

  const actionForm = () => <SeedingDataTable data={seedingData} />
  const responseForm = () => <ResponseToExcel data={dataAfterResponse} insertingData={bulkInsertLoading} />

  return (
    <Wrapper>
      {loading && <LinearProgress />}

      <ContentDialog
        maxWidth='lg'
        primaryText={`Confirm seeding ${tabIndexValue.listTabIndex === 0 ? 'students' : 'teachers'} for ${
          currentClassDetail?.name
        } (${initialSchoolState?.label})`}
        content={actionForm()}
        isOpen={showModal.showVerificationData}
        confirmLabel='Start Seeding'
        onConfirm={() => {
          setShowModal((prevState) => ({
            ...prevState,
            confirmModalActive: false,
            showVerificationData: false,
            showResponseData: true,
          }))
          saveSeedSchoolData(seedingData)
        }}
        onClose={() => {
          setShowModal((prevState) => ({
            ...prevState,
            confirmModalActive: false,
            showVerificationData: false,
          }))
        }}
        cancelLabel='Cancel'
      />

      <ContentDialog
        maxWidth='lg'
        primaryText={`Seeding Summary of ${tabIndexValue.listTabIndex === 0 ? 'students' : 'teachers'} for ${
          currentClassDetail?.name
        } (${initialSchoolState?.label})`}
        content={responseForm()}
        isOpen={showModal.showResponseData}
        noCancelLabel={bulkInsertLoading}
        noConfirmLabel={bulkInsertLoading}
        confirmLabel='Download Excel'
        onConfirm={() => {
          exportExcel(
            dataAfterResponse.students.length ? dataAfterResponse.students : dataAfterResponse.teachers,
            `${dataAfterResponse.students.length ? 'Student' : 'Teacher'} - ${dataAfterResponse.classes[0]?.name}`,
          )
          setShowModal((prevState) => ({
            ...prevState,
            showResponseData: false,
          }))
        }}
        onClose={() => {
          setShowModal((prevState) => ({
            ...prevState,
            showResponseData: false,
          }))
        }}
        cancelLabel='Cancel'
      />

      <ConfirmationModal
        open={showModal.confirmModalActive}
        handleClose={() =>
          setShowModal((prevState) => ({
            ...prevState,
            confirmModalActive: false,
          }))
        }
        title='Seed School'
        confirmationText={`Are you sure you want to ${
          tabIndexValue.listTabIndex === 0
            ? `seed ${parsedExcelData?.students && Object.keys(parsedExcelData?.students)[tabIndexValue.classTabIndex]}`
            : 'seed all teachers'
        }?`}
        confirmationLabel='Continue'
        handleConfirm={handleSeedIndividualClass}
        loading={bulkInsertLoading}
      />

      <ConfirmationModal
        open={showModal.showResetConfirmation}
        handleClose={() =>
          setShowModal((prevState) => ({
            ...prevState,
            showResetConfirmation: false,
          }))
        }
        title='Are you sure you want to reset all information?'
        confirmationLabel='Continue'
        handleConfirm={cancelSeeding}
      />

      <ConfirmationModal
        open={showModal.confirmEditClassName}
        handleClose={() => {
          const classLists = [...parsedExcelData.classList]
          const index = classLists.findIndex((classe) => classe.displayName === currentClassDetail?.displayName)

          // this will re-render the component with the previous data
          if (index > -1) {
            const updatedClassDetail = { ...currentClassDetail }
            const updatedClassList = produce(classLists, (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft[index] = updatedClassDetail
            })

            setParsedExcelData((prevState) => ({
              ...prevState,
              classList: updatedClassList,
            }))
          }

          setShowModal((prevState) => ({
            ...prevState,
            confirmEditClassName: false,
          }))
        }}
        title={`Are you sure you want to edit class name to ${tempData?.className}?`}
        confirmationLabel='Continue'
        handleConfirm={() => {
          const classLists = [...parsedExcelData.classList]
          const students = parsedExcelData.students[currentClassDetail?.name]
          const teachers = parsedExcelData.teachers[currentClassDetail?.name]
          const index = classLists.findIndex((classe) => classe.name === currentClassDetail?.name)
          const updatedStudents = []
          const updatedTeachers = []

          if (index > -1) {
            const updatedClassDetail = { ...currentClassDetail, name: tempData?.className }
            const updatedClassList = produce(classLists, (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft[index] = updatedClassDetail
            })

            for (const student of students) {
              const studentDetail = { ...student }
              delete studentDetail.className
              const newClassName = [tempData?.className]

              const updatedStudentDetail = { ...studentDetail, className: newClassName }
              updatedStudents.push(updatedStudentDetail)
            }

            for (const teacher of teachers) {
              const teacherDetail = { ...teacher }
              delete teacherDetail.className
              const newClassName = [tempData?.className]

              const updatedTeacherDetail = { ...teacherDetail, className: newClassName }
              updatedTeachers.push(updatedTeacherDetail)
            }

            parsedExcelData.students[currentClassDetail?.name] = updatedStudents
            parsedExcelData.teachers[currentClassDetail?.name] = updatedTeachers

            const updatedStudentObjects = renameKey(
              parsedExcelData.students,
              currentClassDetail?.name,
              tempData?.className,
            )
            const updatedTeacherObjects = renameKey(
              parsedExcelData.teachers,
              currentClassDetail?.name,
              tempData?.className,
            )

            setParsedExcelData((prevState) => ({
              ...prevState,
              students: updatedStudentObjects,
              teachers: updatedTeacherObjects,
              classList: updatedClassList,
            }))

            setShowModal((prevState) => ({
              ...prevState,
              confirmEditClassName: false,
            }))

            setSuccessMessage('Class name updated successfully!')
          }
        }}
      />

      <ConfirmationModal
        open={showModal.confirmEditClassDisplayName}
        handleClose={() => {
          const classLists = [...parsedExcelData.classList]
          const index = classLists.findIndex((classe) => classe.displayName === currentClassDetail?.displayName)

          // this will re-render the component with the previous data
          if (index > -1) {
            const updatedClassDetail = { ...currentClassDetail }
            const updatedClassList = produce(classLists, (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft[index] = updatedClassDetail
            })

            setParsedExcelData((prevState) => ({
              ...prevState,
              classList: updatedClassList,
            }))
          }

          setShowModal((prevState) => ({
            ...prevState,
            confirmEditClassDisplayName: false,
          }))
        }}
        title={`Are you sure you want to edit class display name to ${tempData?.displayName}?`}
        confirmationLabel='Continue'
        handleConfirm={() => {
          const classLists = [...parsedExcelData.classList]
          const index = classLists.findIndex((classe) => classe.displayName === currentClassDetail?.displayName)

          if (index > -1) {
            const updatedClassDetail = { ...currentClassDetail, displayName: tempData?.displayName }
            const updatedClassList = produce(classLists, (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft[index] = updatedClassDetail
            })

            setParsedExcelData((prevState) => ({
              ...prevState,
              classList: updatedClassList,
            }))
            setShowModal((prevState) => ({
              ...prevState,
              confirmEditClassDisplayName: false,
            }))

            setSuccessMessage('Class display name updated successfully!')
          }
        }}
      />

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {parsedExcelData && fileName ? (
              <TextField id='filename' label='FileName(s)' value={fileName.join(', ')} disabled required fullWidth />
            ) : (
              <>
                <p className={classes.uploadTitle}>Upload File(s)*</p>
                <label htmlFor='fileUploader'>
                  <Button className={classes.fileButton} component='span'>
                    <span className={classes.uploadIconWrapper}>
                      <CloudUpload />
                      <span> Upload School Seeding File (.xlsx)</span>
                    </span>
                  </Button>
                </label>
                <input
                  id='fileUploader'
                  type='file'
                  multiple
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  onChange={async ({ target }) => {
                    setLoading(true)
                    let parsedData
                    for (const file of target.files) {
                      try {
                        // eslint-disable-next-line no-await-in-loop
                        const parsedIndividualFileData = await parseExcel(file)
                        if (parsedData) {
                          const {
                            classList: newClassList,
                            students: newStudents,
                            teachers: newTeachers,
                          } = parsedIndividualFileData
                          const {
                            classList: prevClassList,
                            students: prevStudents,
                            teachers: prevTeachers,
                          } = parsedData

                          parsedData = {
                            ...parsedData,
                            classList: [...prevClassList, ...newClassList],
                            students: { ...prevStudents, ...newStudents },
                            teachers: { ...prevTeachers, ...newTeachers },
                          }
                        } else {
                          parsedData = parsedIndividualFileData
                        }
                        setFileName((prevState) => [...prevState, file?.name])
                        setParsedExcelData(parsedData)
                      } catch (error) {
                        if (typeof error === 'object') {
                          setErrorMessage(`${error.toString()} for FileName: ${file?.name}`)
                        }
                      }
                    }

                    setLoading(false)
                  }}
                  className={classes.fileTextField}
                  required
                />
              </>
            )}
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              id='startDate'
              label='Start Date'
              onDateChange={handleSubjectStateChanges}
              value={subjectState.startDate || new Date()}
              fullWidth
              disabled={!parsedExcelData}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id='endDate'
              label='End Date'
              onDateChange={handleSubjectStateChanges}
              minDate={subjectState.startDate}
              value={subjectState.endDate || new Date()}
              fullWidth
              disabled={!parsedExcelData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='studentPassword'
              label='Students Password'
              value={password.studentPassword}
              disabled={!parsedExcelData || !editPassword.studentPassword}
              handleChange={(val) => handlePasswordChange('studentPassword', val)}
              fullWidth
              required
              endAdornment={
                upload &&
                password.studentPassword && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='update-password'
                      onClick={() => {
                        setEditPassword((prevState) => ({
                          ...prevState,
                          studentPassword: !prevState.studentPassword,
                        }))
                        if (editPassword.studentPassword) {
                          setSuccessMessage(`Student's password updated successfully`)
                          for (const key in studentsObject) {
                            if ({}.hasOwnProperty.call(studentsObject, key)) {
                              studentsObject[key].forEach((student) => {
                                const studentVal = student
                                studentVal.studentPassword = password.studentPassword
                                return studentVal
                              })
                            }
                          }
                        }
                      }}
                      edge='end'
                    >
                      {editPassword.studentPassword ? <Check /> : <Edit />}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='teacherPassword'
              label='Teachers Password'
              value={password.teacherPassword}
              disabled={!parsedExcelData || !editPassword.teacherPassword}
              handleChange={(val) => handlePasswordChange('teacherPassword', val)}
              fullWidth
              required
              endAdornment={
                upload &&
                password.teacherPassword && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='update-password'
                      onClick={() => {
                        setEditPassword((prevState) => ({
                          ...prevState,
                          teacherPassword: !prevState.teacherPassword,
                        }))
                        if (editPassword.teacherPassword) {
                          setSuccessMessage(`Teacher's password updated successfully`)
                          for (const key in teachersObject) {
                            if ({}.hasOwnProperty.call(teachersObject, key)) {
                              teachersObject[key].forEach((teacher) => {
                                const teacherVal = teacher
                                teacherVal.teacherPassword = password.teacherPassword
                                return teacherVal
                              })
                            }
                          }
                        }
                      }}
                      edge='end'
                    >
                      {editPassword.teacherPassword ? <Check /> : <Edit />}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Grid>

          <Grid item xs={6}>
            <CustomSelectField
              label='School Name'
              id='school'
              key={initialSchoolState}
              items={schoolListState}
              onSelectValue={handleSubjectStateChanges}
              initialValue={initialSchoolState}
              disabled={schoolListLoading || !schoolListState.length || !parsedExcelData}
              isSearchable
              isRequired
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              variant='contained'
              size='medium'
              color='primary'
              type='submit'
              buttonRootClass={classes.loginButtonRoot}
              disabled={
                !(
                  parsedExcelData &&
                  subjectState.school &&
                  subjectState.startDate &&
                  subjectState.endDate &&
                  password.studentPassword &&
                  password.teacherPassword
                ) || upload
              }
              onClick={handleUploadSeedData}
            >
              Upload Seeding
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant='contained'
              size='medium'
              color='primary'
              type='submit'
              buttonRootClass={classes.loginButtonRoot}
              disabled={!parsedExcelData}
              // onClick={cancelSeeding}
              onClick={() =>
                setShowModal((prevState) => ({
                  ...prevState,
                  showResetConfirmation: true,
                }))
              }
            >
              Reset Information
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <div className={classes.tableContent}>
        {parsedExcelData && upload && <VerticalTabs {...verticalTabContent} />}
      </div>
    </Wrapper>
  )
}

SchoolSeeding.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  fetchSchoolList: PropTypes.func,
  schoolListLoading: PropTypes.bool,
  schoolList: PropTypes.array,
  fetchSubjectList: PropTypes.func,
  subjectListLoading: PropTypes.bool,
  subjectList: PropTypes.array,
  saveSeedSchoolData: PropTypes.func,
  bulkInsertLoading: PropTypes.bool,
  bulkInsertSuccessMessage: PropTypes.string,
  bulkInsertErrorMessage: PropTypes.string,
  cleanMessage: PropTypes.func,
  responseDataList: PropTypes.array,
}

const mapStateToProps = createStructuredSelector({
  schoolListLoading: makeSelectSchoolListLoading(),
  schoolList: makeSelectSchoolList(),
  subjectListLoading: makeSelectSubjectListLoading(),
  subjectList: makeSelectSubjectList(),
  bulkInsertLoading: makeSelectBulkInsertLoading(),
  bulkInsertSuccessMessage: makeSelectBulkInsertDataSuccessMessage(),
  bulkInsertErrorMessage: makeSelectBulkInsertDataErrorMessage(),
  responseDataList: makeSelectBulkInsertResponseDataList(),
})

const mapDispatchToProps = (dispatch) => ({
  fetchSchoolList: () => dispatch(getSchoolList()),
  fetchSubjectList: (schoolId) => dispatch(getSubjectList(schoolId)),
  saveSeedSchoolData: (data) => dispatch(postBulkInsert(data)),
  cleanMessage: () => dispatch(clearMessage()),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(SchoolSeeding)
