import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'

import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const CustomTable = ({
  columns,
  data,
  pageSize,
  pageSizeOptions,
  updatable,
  deletable,
  onRowUpdate,
  onRowDelete,
  rowStyleOption,
}) => {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  const [state, setState] = useState()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const formattedData = data.map((d) => {
      const newObj = {}

      columns.forEach((col) => {
        newObj[col.field] = d[col.field]
      })
      return newObj
    })

    setState({
      columns,
      data: formattedData,
    })

    setTimeout(() => {
      setLoading(false)
    }, 0)
  }, [columns, data])

  const deleteFunctionality = (oldData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
        setState((prevState) => {
          const prevData = [...prevState.data]
          prevData.splice(prevData.indexOf(oldData), 1)
          return { ...prevState, prevData }
        })
      }, 600)
      // eslint-disable-next-line no-unused-expressions
      onRowDelete && onRowDelete(oldData)
    })

  const updateFunctionality = (newData, oldData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
        if (oldData) {
          setState((prevState) => {
            const prevData = [...prevState.data]
            prevData[prevData.indexOf(oldData)] = newData
            return { ...prevState, data: prevData }
          })
        }
      }, 600)
      // eslint-disable-next-line no-unused-expressions
      onRowUpdate && onRowUpdate(newData, oldData)
    })

  return (
    !loading && (
      <MaterialTable
        icons={tableIcons}
        columns={state.columns}
        data={state.data}
        options={{
          pageSize: pageSize || 25,
          pageSizeOptions: pageSizeOptions || [25, 50, 100],
          padding: 'dense',
          rowStyle: rowStyleOption,
        }}
        editable={{
          onRowUpdate: updatable && updateFunctionality,
          onRowDelete: deletable && deleteFunctionality,
        }}
      />
    )
  )
}

CustomTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  updatable: PropTypes.bool,
  deletable: PropTypes.bool,
  onRowDelete: PropTypes.func,
  onRowUpdate: PropTypes.func,
  rowStyleOption: PropTypes.func,
}

export default CustomTable
