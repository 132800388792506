import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_ERROR,
  CLEAR_LOGIN_MESSAGE,
  SET_AUTHENTICATION_RESPONSE,
  CHANGE_OTP_INFO,
  POST_OTP_CODE_ERROR,
  POST_OTP_CODE,
  POST_OTP_CODE_SUCCESS,
  POST_RESEND_OTP_ERROR,
  POST_RESEND_OTP,
  POST_RESEND_OTP_SUCCESS,
  CLEAR_RESEND_OTP,
  CLEAR_POST_OTP,
} from './constants'

export const login = (payload) => ({ type: LOGIN, payload })
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload })
export const loginError = (error) => ({ type: LOGIN_ERROR, error })

export const logout = () => ({ type: LOGOUT })
export const logoutSuccess = (payload) => ({ type: LOGOUT_SUCCESS, payload })

export const getProfileInfo = () => ({ type: GET_PROFILE_INFO })

export const getProfileInfoSuccess = (payload) => ({ type: GET_PROFILE_INFO_SUCCESS, payload })

export const getProfileInfoError = (error) => ({ type: GET_PROFILE_INFO_ERROR, error })

export const clearLoginMessage = () => ({ type: CLEAR_LOGIN_MESSAGE })

export const setAuthenticationResponse = (payload) => ({
  type: SET_AUTHENTICATION_RESPONSE,
  payload,
})

export const changeOTPInfo = (data) => ({
  type: CHANGE_OTP_INFO,
  data,
})

export const postOTPCode = (payload) => ({
  type: POST_OTP_CODE,
  payload,
})
export const postOTPCodeSuccess = (payload) => ({
  type: POST_OTP_CODE_SUCCESS,
  payload,
})
export const postOTPCodeError = (error) => ({
  type: POST_OTP_CODE_ERROR,
  error,
})

export const postResendOTP = (payload) => ({
  type: POST_RESEND_OTP,
  payload,
})
export const postResendOTPSuccess = (payload) => ({
  type: POST_RESEND_OTP_SUCCESS,
  payload,
})
export const postResendOTPError = (error) => ({
  type: POST_RESEND_OTP_ERROR,
  error,
})

export const clearResendOTP = () => ({
  type: CLEAR_RESEND_OTP,
})
export const clearPostOTP = () => ({
  type: CLEAR_POST_OTP,
})
