import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  OTPWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 0',
  },
  twoFAInstruction: {
    color: '#3E365B',
    fontSize: '15px',
    fontWeight: 400,
  },
  twoFAButtonWrapper: {
    width: '100%',
  },
  resendContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '24px 0 0',
    fontWeight: 400,
    '& a': {
      color: ' #3f8efc',
      fontSize: '14px',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        color: '#79c040',
        outline: '0px solid transparent',
      },
    },
  },
  resendCodeText: {
    cursor: 'pointer',
    color: '#3f8efc',
    fontSize: '14px',
    '&:hover': {
      color: '#79c040',
      outline: '0px solid transparent',
    },
  },
  resendCodeTextDisabled: {
    cursor: 'auto',
    color: '#a7a7a7',
  },
}))

export default useStyles
