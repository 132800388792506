import React from 'react'
import PropTypes from 'prop-types'

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'

import CustomTable from 'components/CustomTable'

// import useStyles from '../style'

const ResponseToExcel = (props) => {
  //   const classes = useStyles()
  const { data, insertingData } = props
  const { students, teachers } = data

  const isStudentSeed = students.length || false

  const columns = [
    { title: 'Username', field: 'userName' },
    { title: `${isStudentSeed ? 'Student' : 'Teacher'} Name`, field: 'name' },
    { title: 'Password', field: 'password' },
    {
      title: 'Class Names',
      field: 'className',
      editable: 'never',
      render: (rowData) => rowData.className.map((m) => <Chip key={m} variant='outlined' label={m} />),
    },
    {
      title: 'hasError',
      field: 'hasError',
      editable: 'never',
      hidden: true,
      defaultSort: 'desc',
    },
    { title: 'Remarks', field: 'remark' },
  ]

  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          {insertingData ? (
            <CircularProgress />
          ) : (
            <CustomTable
              title='Response Table'
              columns={columns}
              data={isStudentSeed ? students : teachers}
              pageSize={10}
              pageSizeOptions={[10, 25, 50, 100]}
              rowStyleOption={(rowData) => ({
                backgroundColor: rowData.hasError ? '#CF6679' : '#4CAF50',
              })}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

ResponseToExcel.propTypes = {
  data: PropTypes.object,
  insertingData: PropTypes.bool,
}

export default ResponseToExcel
