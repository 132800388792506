import React, { useState, useMemo } from 'react'
import { compose } from 'redux'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Button from 'components/Button'
import OtpInput from 'components/OtpInput'

import { postOTPCode, postResendOTP, clearPostOTP, clearLoginMessage } from 'containers/LoginPage/actions'
import {
  makeSelectEmail,
  makeSelectPostResendOTPLoading,
  makeSelectPostOTPCodeLoading,
  makeSelectPostOTPCodeError,
} from 'containers/LoginPage/selectors'

import useStyles from './style'

const OTP_SIZE = 6

const RESEND_TIME_PERIOD = 300

const ResendButton = ({ handleResend, classes, onClearPostOTP }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [leftTime, setLeftTime] = useState(0)

  const runButtonTimer = () => {
    let disabledTime = RESEND_TIME_PERIOD
    setLeftTime(RESEND_TIME_PERIOD)
    const interval = setInterval(() => {
      if (disabledTime === 1) {
        setButtonDisabled(false)
        setLeftTime(0)
        clearInterval(interval)
      } else {
        disabledTime -= 1
        setLeftTime(disabledTime)
      }
    }, 1000)
  }

  return (
    <div
      className={`${classes.resendCodeText} ${buttonDisabled ? classes.resendCodeTextDisabled : ''} `}
      onClick={() => {
        if (!buttonDisabled) {
          setButtonDisabled(true)
          runButtonTimer()
          handleResend()
          onClearPostOTP()
        }
      }}
      role='presentation'
    >
      {'Resend Code'} {buttonDisabled && <span>({leftTime}s)</span>}
    </div>
  )
}

ResendButton.propTypes = {
  handleResend: PropTypes.func,
  onClearPostOTP: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

const TwoFAForm = ({
  postOTPCodeLoading,
  onPostOTPCode,
  onPostResendOTP,
  emailAddress,
  postOTPCodeError,
  onClearPostOTP,
  onClearLoginFields,
  handleReset,
}) => {
  const classes = useStyles()

  const [otpCode, setOtpCode] = useState('')

  const hiddenEmailMemo = useMemo(() => {
    const [emailName, domain] = emailAddress.split('@')
    const firstPart = `${emailName.substring(0, 2)}***`
    return firstPart + domain
  }, [emailAddress])

  return (
    <>
      <div className={classes.twoFAInstruction}>
        {`Please enter the 6 digit verification code sent to your email address,
        ${hiddenEmailMemo}. The code is valid for 5 minutes.`}
      </div>
      <OtpInput
        value={otpCode}
        onChange={(otp) => {
          setOtpCode(otp)
          if (postOTPCodeError) {
            onClearPostOTP()
          }
        }}
        numInputs={OTP_SIZE}
        isInputNum
        wrapperStyle={classes.OTPWrapper}
        shouldAutoFocus
        hasErrored={!!postOTPCodeError}
        errorMessage={postOTPCodeError}
      />

      <div className={classes.twoFAButtonWrapper}>
        <Button
          variant='contained'
          size='large'
          color='primary'
          fullWidth
          disabled={otpCode.length !== OTP_SIZE || postOTPCodeLoading || !!postOTPCodeError}
          actionLoading={postOTPCodeLoading}
          buttonText='Sign In'
          onClick={() => onPostOTPCode({ otpCode })}
        />
      </div>

      <div className={classes.resendContentWrapper}>
        <ResendButton
          handleResend={() => onPostResendOTP()}
          classes={classes}
          onClearPostOTP={() => {
            setOtpCode('')
            onClearPostOTP()
          }}
        />
        <div
          onClick={() => {
            onClearLoginFields()
            handleReset()
          }}
          role='presentation'
          className={classes.resendCodeText}
        >
          Try Different User
        </div>
      </div>
    </>
  )
}

TwoFAForm.propTypes = {
  postOTPCodeLoading: PropTypes.bool,
  onPostResendOTP: PropTypes.func,
  onPostOTPCode: PropTypes.func,
  emailAddress: PropTypes.string,
  postOTPCodeError: PropTypes.string,
  onClearPostOTP: PropTypes.func,
  onClearLoginFields: PropTypes.func,
  handleReset: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  postResendOTPLoading: makeSelectPostResendOTPLoading(),
  postOTPCodeLoading: makeSelectPostOTPCodeLoading(),
  postOTPCodeError: makeSelectPostOTPCodeError(),
  emailAddress: makeSelectEmail(),
})

const mapDispatchToProps = (dispatch) => ({
  onPostResendOTP: () => dispatch(postResendOTP()),
  onPostOTPCode: (data) => dispatch(postOTPCode(data)),
  onClearPostOTP: () => dispatch(clearPostOTP()),
  onClearLoginFields: () => dispatch(clearLoginMessage()),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(TwoFAForm)
